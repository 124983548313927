<template>
  <footer>
    <div class="footerElements">
      <div class="branding">
        Dakdragerverhuur.nl
      </div>
      <div class="copyright">
        © 2020 - {{ currentYear }} | Website door Robin Uitbeijerse | Design door Marjolein Uitbeijerse
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "AppFooter",
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>

<style lang="scss">
footer {
  position: relative;
  bottom: 0;
  width: 100vw;
  height: 75px;

  .footerElements {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 500px;

    div {
      margin: 10px;
    }

    .branding {
      text-transform: uppercase;
      font-size: 14px;
    }

    .copyright,
    .contact {
      font-size: 12px;
      margin: auto 0;
    }

    .contact {
      color: rgb(44, 62, 80);
      text-decoration: underline;
      margin-bottom: 10px;
    }
  }
}
</style>
