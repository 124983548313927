<template>
  <div class="home">
    <div class="image">
      <img
        src="@/assets/images/overig/logo-with-text.png"
        alt="Koenigsegg met Thule dakkoffer"
      />
      <button @click="$router.push('reserveren')" class="CTA">
        Reserveer direct
      </button>
    </div>
    <div class="reviews">
      <h1>
        Wat onze huurders zeggen:
      </h1>
      <RatingsCarousel />
      <p>
        Lees meer dan 150 ervaringen met dakdragerverhuur op
        <a
          href="https://www.huren.nl/m3298/dakdragerverhuur-eu"
          target="_blank"
        >
          <b>Huren.nl</b>
        </a>
      </p>
      <a href="https://www.huren.nl/m3298/dakdragerverhuur-eu" target="_blank">
        <img
          src="@/assets/images/overig/huren.png"
          alt="Aanbevolen door huren.nl"
          id="huren"
        />
      </a>
    </div>
  </div>
</template>

<script>
import RatingsCarousel from "../components/RatingsCarousel";

export default {
  name: "AppHome",
  components: {
    RatingsCarousel,
  },
};
</script>

<style lang="scss" scoped>
.home {
  .image {
    margin: 60px 0 40px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;

    img {
      width: 100%;
      max-width: 600px;
    }

    .CTA {
      line-height: 35px;
      cursor: pointer;
      border: none;
      background-color: rgb(62, 164, 71);
      text-transform: uppercase;
      text-decoration: none;
      color: white;
      font-size: 14px;
      font-weight: bold;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      height: 35px;
      padding: 0 30px;
    }
  }
  .reviews {
    h1 {
      font-size: 26px;
    }
    p {
      font-size: 14px;
      a {
        text-decoration: none;
        color: black;
      }
    }

    #huren {
      width: 150px;
      margin: 5px 0;
    }
  }
}
</style>
